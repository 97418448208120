import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link} style={{ 
                fontSize: '30px', 
                fontWeight: 'bold', 
                color: '#079A4A',
                textShadow: '2px 2px 4px #ffffff'
              }}>
                <img src={require("assets/img/brand/pakmart_logo.png")} alt="Pakmart Logo" style={{ 
                height: '70px', 
                width: '70px', 
                marginRight: '10px' 
              }} />
                Pakmart                
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >                              
                <Nav className="align-items-lg-center ml-lg-auto" navbar>                  
                  <NavItem className="mr-5">
                    <ScrollLink                      
                      activeClass="active"
                      to="products"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}                                           
                    >
                      <h6 className="text-white">Products</h6> <span className="sr-only">(current)</span>
                    </ScrollLink>                    
                  </NavItem>
                  <NavItem className="mr-5">
                    <ScrollLink                      
                      activeClass="active"
                      to="teams"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}                                                                
                    >
                      <h6 className="text-white">Our Team</h6> <span className="sr-only">(current)</span>
                    </ScrollLink>                    
                  </NavItem>
                  <NavItem>
                    <ScrollLink                      
                      activeClass="active"
                      to="contact"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}                                                          
                    >
                      <h6 className="text-white">Contact Us</h6> <span className="sr-only">(current)</span>
                    </ScrollLink>                    
                  </NavItem>                  
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
