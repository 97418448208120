import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import emailjs from '@emailjs/browser';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  sendEmail(e) {
    e.preventDefault(); // Prevents default refresh by the browser
    
    emailjs.init("qXerkyBkhbwCiISUg");
    emailjs.sendForm('service_bpeayt9', 'template_s0ep2fv', e.target)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                      PakMart - Your Gateway to Premium Pakistani Exports                        
                      </h1>
                      <p className="lead text-white">
                      At PakMart, we connect you to the richness of Pakistan’s local agriculture. Our passion is to share high-quality, sustainably sourced Pakistani rice, fresh vegetables, and pure Himalayan salt with the world. Providing a taste of our traditions to the World.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section id="products" className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Rice
                          </h6>
                          <p className="description mt-3">
                          Experience the unparalleled richness and flavor of our Pakistani-grown premium rice, a staple of quality meals around the globe.
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                            Superior Quality
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                            Authentically Pakistani
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                            Unmatched Flavor
                            </Badge>
                          </div>                          
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div>
                          <h6 className="text-success text-uppercase">
                          Vegetables
                          </h6>
                          <p className="description mt-3">
                          Indulge in the vibrant freshness and nutritional bounty of our hand-picked vegetables, a testament to Pakistan's fertile soils.
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                            Freshly Harvested
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                            Nutrient-Enrich Vegies
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                            Organically Grown
                            </Badge>
                          </div>                          
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                          Himalayan Salt
                          </h6>
                          <p className="description mt-3">
                          Elevate your culinary experience with our authentic Himalayan salt, a pure and natural gift from the heart of our majestic mountains.
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                            Pure and Natural
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                            Trace Mineral Rich
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                            Unrefined Authenticity
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href={require("assets/files/catalog.pdf")}
                            target="_blank"
                            download
                          >
                            <span className="btn-inner--icon">
                              <i className="fa fa-cloud-download mr-2" />
                            </span>
                            Download Catalog
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/export.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Why Choose PakMart?</h3>
                    <p>
                    PakMart stands at the forefront of exporting Pakistan's finest products. Our commitment to quality, integrity, and sustainability ensures you receive the very best from us. We are your trusted partner in sourcing premium commodities.
                    </p>
                  </div>
                </Col>                
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/products.jpg")}
                  />
                </Col>
                <Col className="order-md-2" md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Our Products</h3>
                    <p>
                    Explore our wide range of products meticulously sourced and carefully packed. Our range includes premium quality rice, hand-picked fresh vegetables, and authentic Himalayan salt that enriches your dishes with unmatched flavors.
                    </p>
                  </div>
                </Col>                
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/sourcing.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Sustainable Sourcing</h3>
                    <p>
                    At PakMart, sustainability is not just a trend; it’s a practice. We source our products from sustainable farmers and producers in Pakistan, ensuring a minimal environmental footprint while supporting local communities.
                    </p>
                  </div>
                </Col>                
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/global.jpg")}
                  />
                </Col>
                <Col className="order-md-2" md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Global Reach</h3>
                    <p>
                    Delivering a piece of our Pakistani heritage to your doorstep. With a strong distribution network spanning the Middle East, Canada, and the UK, we ensure our products reach you, no matter where you are.
                    </p>
                  </div>
                </Col>                
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/Single.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Export Process</h3>
                    <p>
                    Our export process is as robust as it is meticulous. From careful selection and packaging to efficient transportation, we manage every aspect ensuring our products reach you in their freshest and most authentic state.
                    </p>
                  </div>
                </Col>                
              </Row>
            </Container>
          </section>          
          <section id="teams" className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Our amazing Team</h2>
                  <p className="lead text-muted">
                  Our team at PakMart is a dynamic blend of experienced professionals and passionate enthusiasts dedicated to promoting Pakistan's agricultural richness. From our meticulous product sourcing team in Pakistan to our diligent distribution network across Oman, UAE, Canada, and the UK, we work collectively to ensure our customers receive the best. Our common thread? A shared passion for quality, sustainability, and service that binds us in our mission of bringing the taste of Pakistan to the world.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/team/shahid.jpeg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Sheikh Shahid</span>
                        <small className="h6 text-muted">Cheif Executive Officer</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="tel:+923214014469"
                          target="_blank"
                        >
                          <i className="fa fa-phone" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="mailto:shahid@pakmart.co"
                          target="_blank"
                        >
                          <i className="fa fa-envelope" />
                        </Button>                       
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/team/faheem.jpeg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Muhammad Faheem</span>
                        <small className="h6 text-muted">
                        Director
                        </small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="tel:+923008487404"
                          target="_blank"
                        >
                          <i className="fa fa-phone" />
                        </Button>                        
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/team/abdullah.jpeg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Abdulah Ahmad</span>
                        <small className="h6 text-muted">Director Sales Pakistan</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="tel:+923238416360"
                          target="_blank"
                        >
                          <i className="fa fa-phone" />
                        </Button>                        
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/team/uzair.jpeg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Uzair Hanif</span>
                        <small className="h6 text-muted">Director Marketing Oman</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="tel:+96891705524"
                          target="_blank"
                        >
                          <i className="fa fa-phone" />
                        </Button>                       
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Need to Discuss Something?</h2>
                  <p className="lead text-white">
                  We value open communication with our valued customers and partners. Our dedicated customer service team is ready to answer all your inquiries and provide the information you need about our products and services. Whether you're curious about our sourcing process, want to know more about our product range, or simply want to share your experience with PakMart, we're all ears. Reach out to us through our phone line or email. We're here for you, and we promise to get back to you promptly.
                  </p>
                </Col>
              </Row>              
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section id="contact" className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Reach out us?</h4>
                      <p className="mt-0">
                        We will get back to you really soon
                      </p>
                      <form onSubmit={this.sendEmail}>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Your name"
                            type="text"
                            name="from_name"
                            onFocus={(e) =>
                              this.setState({ nameFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ nameFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            name="reply_to"
                            onFocus={(e) =>
                              this.setState({ emailFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ emailFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="message"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="submit"
                        >
                          Send Message
                        </Button>
                      </div>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
